<template>
  <!-- Sidebar starts -->
  <div class="flex flex-no-wrap h-screen bg-lightGrey pt-16 overflow-hidden">
    <div class="z-30 block top-16 w-16" id="mobile-nav">
      <div
        class="bg-teal shadow overflow-scroll overflow-x-hidden transition-width transition-slowest ease"
        id="forHeight"
        :class="[moved ? 'w-16' : 'w-64']"
      >
        <div
          id="openSideBar"
          class="h-10 w-10 absolute flex items-center justify-center cursor-pointer"
          :class="[moved ? 'left-5' : 'left-52']"
          @click="sidebarHandler(true)"
        >
          <span v-if="!moved" class="material-icons text-white"> close </span>
          <span
            v-else
            class="text-white text-2xl material-icons transform rotate-180"
            >menu_open
          </span>
        </div>
        <div class="pt-14 text-white" :class="[moved ? 'px-2' : 'px-8']">
          <router-link :to="{ name: 'cs-dashboard' }">
            <span
              class="flex w-full cursor-pointer items-center mb-1 hover:text-darkGrey"
            >
              <div class="py-1 px-3 flex items-center justify-center text-xs">
                <span class="material-icons">dashboard </span>
              </div>
              <div class="flex items-end">
                <span class="text-sm" :class="[moved ? 'hidden' : '']"
                  >Dashboard</span
                >
              </div>
              <!-- Notification -->
              <!-- <div
                  class="py-1 px-1 ml-2 bg-red rounded-full text-white flex items-center justify-center text-xs"
                >
                  5
                </div> -->
              <!-- Notification -->
            </span>
          </router-link>
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="pt-4"
          >
            <div v-if="!moved">
              <router-link :to="{ name: category.link }">
                <span
                  @click="category.open = !category.open"
                  class="flex w-full cursor-pointer items-center mb-1 hover:text-darkGrey"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>

                  <div class="flex items-end">
                    <span class="text-sm" :class="[moved ? 'hidden' : '']">{{
                      category.name
                    }}</span>
                  </div>
                </span>
              </router-link>
            </div>
            <div v-else>
              <span
                @click="category.open = !category.open"
                class="flex w-full cursor-pointer items-center mb-1 hover:text-darkGrey"
              >
                <router-link
                  v-if="!category.query"
                  :to="{ name: category.link }"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>
                </router-link>
                <router-link
                  v-else
                  :to="{
                    name: category.link,
                    query: { activeTab: category.query },
                  }"
                >
                  <div
                    class="py-1 px-3 flex items-center justify-center text-xs"
                  >
                    <span class="material-icons"> {{ category.icon }} </span>
                  </div>
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sidebar ends -->
    <!-- Content Start -->
    <central-card>
      <slot></slot>
    </central-card>
    <!-- Content Ends -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      moved: false,
      categories: {
        humanResourcesGroup: {
          name: "Rooms",
          icon: "home",
          open: false,
          link: "cs-rooms",
        },
        accountingNFinanceGroup: {
          name: "Policies",
          icon: "description",
          open: false,
          link: "cs-policies",
        },
        complianceGroup: {
          name: "Communication Book",
          icon: "book",
          open: false,
          link: "cs-communicationBook",
        },
      },
    };
  },
  methods: {
    sidebarHandler() {
      // var sideBar = document.getElementById("mobile-nav");
      // sideBar.style.transform = "translateX(-260px)";
      // sideBar.style.transform = "translateX(0px)";
      if (this.moved) {
        // sideBar.style.transform = "translateX(0px)";
        // sideBar.style.transform = "translateX(0px)";
        this.moved = false;
      } else {
        // sideBar.style.transform = "translateX(-260px)";
        // sideBar.style.transform = "translateX(0px)";
        this.moved = true;
      }
    },
  },
  watch: {
    $route() {
      if (!this.moved) {
        this.sidebarHandler();
      }
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  color: #ffb81c;
}
#forHeight {
  height: calc(100vh - 64px);
}
</style>
