<template>
  <div>
    <navigation-bar
      v-if="isLoggedIn"
      :userName="userName"
      @user-logout="logout"
    ></navigation-bar>
    <section>
      <side-navigation>
        <slot />
      </side-navigation>
    </section>
  </div>
</template>

<script>
import SideNavigation from "@/components/ui/CS/sideNavCleaner.vue";
export default {
  name: "csContainer",
  components: {
    SideNavigation,
  },
  methods: {
    async logout() {
      const tokenPayload = localStorage.getItem("token");
      try {
        await this.$store.dispatch("auth/logout", tokenPayload);
        this.$router.push("/");
      } catch (err) {
        this.error = err.message || "Something went wrong, try later.";
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    userName() {
      return this.$store.getters["auth/userName"];
    },
    initials() {
      return localStorage.getItem("initials");
    },
  },
};
</script>

<style scoped>
.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
